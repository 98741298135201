import { inject, Injectable, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subject, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {


  private headerTemplateSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private headerTittleSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private headerButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private headerExtraContent: BehaviorSubject<TemplateRef<any> | null> = new BehaviorSubject<TemplateRef<any> | null>(null);
  private loading: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject<{ [key: string]: boolean; }>({});
  private headerunctionSubject = new Subject<Function>();
  headerTemplate$: Observable<any> = this.headerTemplateSubject.asObservable();
  headerTittle$: Observable<string> = this.headerTittleSubject.asObservable();
  headerButton$: Observable<boolean> = this.headerButtonSubject.asObservable();
  headerExtraContent$: Observable<TemplateRef<any> | null> = this.headerExtraContent.asObservable();
  private disableLoading$ = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loading.pipe(
    //tap(x => console.log('loading', x)),
    map(x => Object.keys(x).length >0 && Object.keys(x).some(prop => x[prop] == true )),
    switchMap(x => this.disableLoading$.pipe(map(disable => disable? false : (!disable && x)))),
    distinctUntilChanged())
  private router$ = inject(Router)
  constructor() {
    this.router$.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(event => {
        this.setHeaderExtraContent(null);
    });
  }

  setHeaderTemplate(template: any): void {
    this.headerTemplateSubject.next(template);
  }

  setHeaderTittle(template: any): void {
    this.headerTittleSubject.next(template);
  }

  setHeaderButton(show: boolean): void {
    this.headerButtonSubject.next(show)
  }
  showLoading(key : string): void {
    this.loading.next({...this.loading.value, [key]: true})
  }
  hideLoading(key: string): void {
    const data = { ...this.loading.value, [key]: false };
    if (this.allLoaded(data)) {
      this.loading.next({});
    }else{
      this.loading.next({ ...this.loading.value, [key]: false })
    }
  }
  resetLoading(): void {
    this.loading.next({});
  }

  sendFunction(func: Function) {
    this.headerunctionSubject.next(func);
  }

  setHeaderExtraContent(template: TemplateRef<any> | null): void {
    this.headerExtraContent.next(template);
  }

  getFunction(): Observable<Function> {
    return this.headerunctionSubject.asObservable();
  }
  disableLoading(boolean: boolean): void {
    this.disableLoading$.next(boolean);
  }
  private allLoaded(value: { [key: string]: boolean; }) {
    return Object.keys(value).every(x => value[x] == false);
  }
}
